/* Desktop styles */
@media screen and (min-width: 768px) {
  .date-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5rem;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .date-picker-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* Desktop Styles */
@media screen and (min-width: 768px) {
  .date-picker-label {
    flex: 0 0 40%;
    
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    font-family: sofia-pro, sans-serif;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .date-picker-label {    
    font-size: 1.2em;
    font-weight: bold;
    font-family: sofia-pro, sans-serif;

    padding: 1rem;
  }
}

.date-picker {
  position: relative;
}

.date-picker:focus {
  outline: none;
}

/* Desktop Styles */
@media screen and (min-width: 768px) {
  .date-picker select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: larger;
    padding: 0.2em 1.5em 0.2em 0.2em;
    border-radius: 0;
    border: 3px solid #1b1814;
    color: #1b1814;
    background-color: transparent;
    cursor: pointer;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .date-picker select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: larger;
    padding: 0.2em 1.5em 0.2em 1em;
    border-radius: 0;
    border: 3px solid #1b1814;
    color: #1b1814;
    background-color: transparent;
    cursor: pointer;
  }
}

/* Desktop Styles */
@media screen and (min-width: 768px) {
  .date-picker::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 4rem;
    pointer-events: none;

    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid #1b1814;
    top: 48%;
  }
}
 
/* Mobile Styles */
@media screen and (max-width: 768px) {
  .date-picker::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 1rem;
    pointer-events: none;

    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid #1b1814;
    top: 40%;
  }
}