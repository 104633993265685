.booking-filter-container {
    display: grid;
    justify-items: space-between;

    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        justify-content: center;
        align-items: center;
    }
}

.booking-filter-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        min-width: 100%;
    }
}

.booking-filter-label {
    font-family: sofia-pro, sans-serif;
    font-size: 0.8em;
    padding-right: 0.7rem;
    
    @media (max-width: 768px) {
    padding-right: 0;
    }
}

.booking-filter-input {
    border: 3px solid #1b1814;
    padding: 0.3rem;
    font-size: larger;
}


.booking-filter-dropdown-select {
    display: flex;
    justify-content: center;
    align-items: center;

    border: 3px solid #1b1814;
    border-radius: 0;
    padding: 0.3rem;

    font-size: 0.8em;
    
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='50' viewBox='0 0 24 24' width='50' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    background-color: white;
    
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
        outline: none;
    }
    
    @media (max-width: 768px) {
        margin: 0 30vw 1rem 30vw;
    }
}
