.time-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 1rem;
    }
}

.time-picker-label {
  text-align: left;
  font-size: 1.2em;
  font-weight: bold;
  font-family: sofia-pro, sans-serif;
}

/* Mobile Sytles */
@media screen and (max-width: 768px) {
  .time-picker-label {
    flex: 1;
    padding-bottom: 0.6rem;
  }
}

/* Desktop Sytles */
@media screen and (min-width: 768px) {
  .time-picker-label {
    flex: 0 0 60%;

    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    font-family: sofia-pro, sans-serif;
  }
}

.time-picker {
  position: relative;
}

.time-picker:focus {
  outline: none;
}

.time-picker select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: larger;
  padding: 0.2em 1.3em 0.2em 1em;
  border-radius: 0;
  border: 3px solid #1b1814;
  color: #1b1814;
  background-color: transparent;
  cursor: pointer;
}

/* Desktop styles */
@media screen and (min-width: 768px) {
  .time-picker::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 0.6rem;
    pointer-events: none;

    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid #1b1814;
    top: 50%;
  }
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .time-picker::after {
    --size: 0.3rem;
    position: absolute;
    content: "";
    right: 0.6rem;
    pointer-events: none;

    border-left: var(--size) solid transparent;
    border-right: var(--size) solid transparent;
    border-top: var(--size) solid #1b1814;
    top: 50%;
  }
}