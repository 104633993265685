.toggle-switch-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: 768px) {
        padding-bottom: 1rem;
    }
}

.toggle-switch-tag {
    font-family: sofia-pro, sans-serif !important;
}

.toggle-switch-label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: #767676;
}

.toggle-switch-label:checked {
    color: #1b1814;
}

.toggle-switch-label-text {
    font-size: 0.8rem;
    font-family: sofia-pro, sans-serif;
}

.toggle-switch {
    position: relative;
    width: 2rem;
    height: 1.1rem;
    background: #b3b3b3;
    border-radius: 32px;
    padding: 0.5rem;
    transition: 300ms all;

    /* Psuedo element */
    &:before {
        transition: 300ms all;
        content: '';
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        top: 50%;
        left: 0.2rem;
        background: white;
        transform: translateY(-50%);
    }
}

.toggle-switch-input {
    display: none;

    &:checked + .toggle-switch {
        background-color: #1b1814;

        &:before {
            transform: translate(0.8rem, -50%);
        }
    }
}
