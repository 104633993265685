.text-area {
    width: 100%;
    height: 2rem;

    border: 3px solid #1b1814;

    font-size: 0.65rem;
    resize: none;
    padding: 0.4rem;
    overflow: hidden;
    font-family: sofia-pro, sans-serif;

    &:focus {
        outline: none;
        border-color: #ED8027;
    }

    &:disabled {
        background-color: transparent;
        border-color: #767676;
    }

    ::placeholder {
        color: #767676;
    }
}
