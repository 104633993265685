@font-face {
    font-family: 'Adjusted Trebuchet MS Fallback';
    src: local('Trebuchet MS');
    size-adjust: 82%;
    ascent-override: 112%;
    descent-override: normal;
    line-gap-override: normal;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-family: 'Adjusted Trebuchet MS Fallback';
    font-weight: 700;
    font-style: normal;
}

html {
    font-size: 24px;
}

@page {
    size: landscape;
}

@media (max-width: 768px) {
    html {
        font-size: 20px;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
        font-size: 20px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
label {
    font-family: 'Russo One', 'Adjusted Trebuchet MS Fallback';
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
    margin-top: 0;
}

h2 {
    font-size: 1.5rem;
}

h3 {
    font-size: 1rem;
}

input,
select,
pre {
    font-family: sofia-pro, sans-serif;
    font-style: normal;
}

.date-picker {
    width: 100%;
    height: 50px;
    border: none;
    font-size: 0.8em;
    text-align: center;
}

.date-picker:focus {
    outline: none;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
